import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

const data = (function () {
  let imgArray = [];
  for (let i = 1; i <= 8; i++) {
    let imgUrl = `https://chinmayavvdelhi.ac.in/assets/images/calendar/06-nov-23-2/pic${i}.JPG`;
    imgArray.push(imgUrl);
  }

  return imgArray;
})();

export default function KaradiShopWorkshop() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">
          Karadi Path Workshop of Science language learning
        </h1>

        <p>
          Chinmaya Vidyalaya, New Delhi recently conducted a workshop on Karadi
          Path Methodology for its Primary and Pre-Primary educators.
          <br />
          <br />
          Karadi path methodology is an award-winning pedagogy for language
          learning through an immersive multi-modal learning environment.
          <br />
          <br />
          Dr. Kalpesh Bordawekar, Sr VP of Karadi Path Education conducted the
          workshop on the Science of Language Learning and an Introduction to
          Karadi Path.
          <br />
          <br />
          The teachers were trained on innovative teaching practices that
          included music, storytelling, and activities.
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
